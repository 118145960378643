<template>
  <div v-if="projectId">
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
        <div class="px-4 md:px-10 mx-auto w-full">
          <div>
            <!-- Card stats -->
            <div class="flex flex-wrap" style="min-height: 100px"></div>
            <!-- setup -->
          </div>
        </div>
      </div>
      <div class="px-4 md:px-10 mx-auto w-full -m-64">
        <router-view />
        <footer-admin />
      </div>
    </div>
  </div>
  <div v-else>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="
            absolute
            top-0
            w-full
            h-full
            bg-blueGray-800 bg-no-repeat bg-full
          "
          :style="`background-image: url('${registerBg2}');`"
        ></div>
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
              <div
                ref="signInContainer"
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-blueGray-200
                  border-0
                "
              >
                <div class="my-auto px-4 lg:px-10 py-10 pt-0">
                  Project is setting up...
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import { db, auth } from '@/firebase'
import { mapGetters } from 'vuex'
import registerBg2 from "@/assets/img/register_bg_2.png";

export default {
  name: "admin-edit-layout",
  components: {
    AdminNavbar,
    Sidebar,
    FooterAdmin,
  },
  data() {
    return {
      registerBg2: registerBg2,
      authListner: null
    }
  },
  created() {
    this.emitter.on("signOut", this.unlinkListner)
  },
  beforeUnmount() {
    this.emitter.off("signOut", this.unlinkListner)
  },
  computed: {
    ...mapGetters(['projectId', 'projectActive', 'notionCode']),
  },

  async mounted() {
    this.authListner = auth.onAuthStateChanged(async (user) => {
      if (user) {
        var uid = user.uid
        await this.checkProject(uid)
      } else {
        this.$message.warning('Please Login')

        this.$router.push('/login')
        return
      }
    })
  },
  methods: {
    unlinkListner() {
      this.authListner && this.authListner()
    },
    async checkProject(uid) {
      const doc = await db.collection('projects').doc(uid).get()
      if (doc.exists) {
        const project = doc.data()
        project.id = doc.id
        this.$store.commit('SET_PROJECT', project)
        if (this.notionCode) {
          this.$router.push({ path: '/notionSuccess', query: { code: this.notionCode } })
        }
        return project
      } else {
        let that = this
        setTimeout(function () { that.checkProject(uid) }, 5 * 1000)
        return null
        // this.$router.push('/auth/login')
        // return
      }
    }
  }
}
</script>
