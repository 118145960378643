/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/
import { createStore } from 'vuex'
import {db,auth,functions} from '@/firebase'
import { getCurrentPlan } from '@/utils/offre'

const store = createStore({
  state: {
    fixedUid:null,
    siteGlobal: {},
    globalPage: {},
    homePage: {},
    contactPage:{},
    blogPage:{},
    featuresPage:{},
    pricingPage:{},
    postsPage:{},
    partnersTable:{},
    salePage:{},
    projectId:null,
    previewLink: null,
    colors: {},
    builds:[],
    domain: null,
    projectActive: false,
    notionCode: null,
    notionWorkspace:null,
    stripe: {},
    apiKey:null,
    stripeCustomerId:null,
    currentSubscription:null,
    customSubscription:null,
    notionUsers:null,
    emails:null,
    currentPlan:null,
    project: null,
    firebaseConfig:null,
    crispConfig:null
  },
  mutations: {
    
    SET_EMAILS: (state, emails) => {
      state.emails = emails
    },
    SET_PAGE: (state, {pageName, page}) => {
      state[pageName] = page
    },
    SET_PROJECT: (state, project) => {
      if(project){
        state.project = project
        state.firebaseConfig = project.firebaseConfig
        state.crispConfig = project.crispConfig
        state.domain = (project.siteGlobal || {}).domain
      state.siteGlobal = project.siteGlobal || {}
      state.homePage = project.homePage || {}
      state.contactPage = project.contactPage || {}
      state.blogPage = project.blogPage || {}
      state.apiKey = project.apiKey
      state.featuresPage = project.featuresPage || {}
      state.pricingPage = project.pricingPage || {}
      state.globalPage = project.globalPage || {}
      state.postsPage = project.postsPage || {}
      state.partnersTable = project.partnersTable || {}
      state.projectActive = project.active
      state.notionWorkspace = project.notionWorkspace
      state.salePage = project.salePage || {}
      state.projectId = project.id
      state.previewLink = project.previewLink
      state.colors = project.colors
      state.stripe = project.stripe || {}
      state.stripeCustomerId = project.stripe_customer_id
      state.currentSubscription = project.currentSubscription
      state.customSubscription = project.customSubscription
      if(state.currentSubscription || state.customSubscription){
        let currentPlan = getCurrentPlan({trialEndDate: project.trialEndDate, customSubscription:state.customSubscription,currentSubscription:state.currentSubscription})
         state.currentPlan = currentPlan
      }
      } else {
        state.projectId = null
      state.apiKey = null

      }
      

    },
    SET_BUILDS: (state, builds) => {
      state.builds = builds || []
    },
    SET_STRIPE: (state, stripe) => {
      state.stripe = stripe || {}
    },
    SET_firebaseConfig: (state, firebaseConfig) => {
      state.firebaseConfig = firebaseConfig 
    },
    SET_crispConfig: (state, crispConfig) => {
      state.crispConfig = crispConfig 
    },
    
    SET_NOTION_CODE: (state, code) => {
      state.notionCode = code
    },
    SET_NOTION_USERS: (state, users) => {
      state.notionUsers = users
    }
    
    
  },
  actions:{
    async getIntegratedEmails({ commit, state }, {projectId}) {
      if(state.emails){
        return state.emails
      }
      const snapshot = await db.collection('projects').doc(projectId).collection('integrations').doc('email').collection('emails').get()
      const emails = []
      snapshot.forEach((doc) => {
        const email = { id: doc.id, ...doc.data() }
        emails.push(email)
      })
      commit('SET_EMAILS', emails)
      return emails
    },
    async getNotionUsers({ commit,state }, {projectId, apiKey}) { // eslint-disable-line no-unused-vars
      if(!auth.currentUser){
        return []
      }
      if(state.notionUsers){
        return state.notionUsers
      }
      var notionUsersList = functions.httpsCallable('notionUsersList')
      const result = await notionUsersList({ projectId: projectId, apiKey:apiKey})
      if (result.data.error) {
        throw (result.data.error)
      }
      if (result && result.data && result.data.results && !this.persons || this.persons.length === 0) {
        const users = result.data.results.filter(u => u.type === 'person')
        commit('SET_NOTION_USERS',users)
        return users
      }
      return []
    }
   
  },
  getters: {
    uid : state => {
      if(state.fixedUid){
        return state.fixedUid
      } else {
        return auth.currentUser?.uid
      }
    },
    siteGlobal: state => state.siteGlobal || {},
    project: state => state.project || {},
    firebaseConfig: state => state.firebaseConfig || {},
    crispConfig: state => state.crispConfig || {},
    
    globalPage: state => state.globalPage || {},
    homePage: state => state.homePage || {},
    contactPage: state => state.contactPage || {},
    blogPage: state => state.blogPage || {},
    stripeCustomerId: state => state.stripeCustomerId,
    currentSubscription: state => state.currentSubscription,
    customSubscription: state => state.customSubscription,
    currentPlan: state => state.currentPlan,
    
    featuresPage: state => state.featuresPage || {},
    pricingPage: state => state.pricingPage || {},
    workspace_name: state => state.notionWorkspace?.workspace_name,
    salePage: state => state.salePage || {},
    postsPage: state => state.postsPage || {},
    partnersTable: state => state.partnersTable || {},
    notionCode: state => state.notionCode,
    projectId: state => state.projectId ,
    apiKey: state => state.apiKey ,
    previewLink: state => state.previewLink,
    domain: state => state.domain,
    colors: state => state.colors || {},
    builds: state => state.builds || [],
    projectActive: state => state.projectActive,
    stripe: state => state.stripe
  }
})

export default store
