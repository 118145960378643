function dateyyyyMM(date, separator = '') {
  var mm = date.getMonth() + 1 // getMonth() is zero-based

  return [date.getFullYear(), (mm > 9 ? '' : '0') + mm].join(separator)
}

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

exports.dateyyyyMM = dateyyyyMM
exports.isValidDate = isValidDate
