<template>
  <!-- Header -->
  <div class="relative bg-emerald-600 md:pt-32 pb-32 pt-12">
    <div class="px-4 md:px-10 mx-auto w-full">
      <div>
        <!-- <el-row
          v-if="!workspace_name"
          class="
            min-w-0
            break-words
            mb-6
            shadow-lg
            rounded
            bg-white
            text-center
          "
        >
          <el-col
            class="text-left px-12"
            :xs="24"
            :sm="24"
            :md="16"
            :lg="16"
            :xl="16"
          >
            <h2 class="text-2xl">Duplicate Notion Template</h2>
            <p>#1 Copy Demo Website template to your Notion</p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="flex h-full">
              <el-button
                class="font-semibold my-auto w-full mx-0"
                type="primary"
                @click="goToDemoTemplate"
                >Start to Duplicate
              </el-button>
            </div>
          </el-col>
        </el-row> -->

        <!-- Card stats -->
        <div class="flex flex-wrap">
          <div
            v-for="stat in stats"
            :key="stat.statSubtitle"
            class="w-full lg:w-6/12 xl:w-3/12 px-4"
          >
            <card-stats
              :statSubtitle="stat.statSubtitle"
              :statTitle="stat.statTitle"
              :statArrow="stat.statArrow"
              :statPercent="stat.statPercent"
              :statPercentColor="stat.statPercentColor"
              :statDescripiron="stat.statDescripiron"
              :statIconName="stat.statIconName"
              :statIconColor="stat.statIconColor"
            />
          </div>
        </div>
        <!-- setup -->
      </div>
    </div>
  </div>
</template>

<script>
import CardStats from "@/components/Cards/CardStats.vue";
import { db } from '@/firebase'
import { mapGetters } from 'vuex'
import { dateyyyyMM } from '@/utils/dateFormatter'

export default {
  name: 'HeaderStats',
  components: {
    CardStats
  },
  data() {
    return {
      thisMonthStats: null,
      allMonthStats: [],
      stats: []

    }
  },
  computed: {
    ...mapGetters(['uid', 'workspace_name', 'projectActive', 'currentSubscription', 'currentPlan']),

  },
  async created() {
    if (this.uid) {
      const today = new Date()
      const monthStr = dateyyyyMM(today)
      const snapshot = await db.collection('projects').doc(this.uid).collection('statistics').get()
      snapshot.forEach((doc) => {
        const data = { id: doc.id, ...doc.data() }
        if (data.id === monthStr) {
          this.thisMonthStats = data
        }
        this.allMonthStats.push(data)
      })
      //



      const maxBuildTimePerMonth = this.currentPlan ? ((this.currentPlan.maxValues || {}).buildTime || 0) : 0
      const maxActionPerMonth = this.currentPlan ? ((this.currentPlan.maxValues || {}).actions || 0) : 0
      const maxWebform = this.currentPlan ? ((this.currentPlan.maxValues || {}).webformSubmissions || 0) : 0

      this.stats = [
        {
          statSubtitle: "Files Generated",
          statTitle: this.thisMonthStats?.files || 0,
          statArrow: null,
          statPercent: '',
          statPercentColor: "text-emerald-500",
          statDescripiron: "Automated Files Generation",
          statIconName: "far fa-chart-bar",
          statIconColor: "bg-red-500"
        },
        {
          statSubtitle: "BUILD TIME",
          statTitle: (this.thisMonthStats?.buildTime || 0) / 1000,
          statArrow: null,
          statPercent: maxBuildTimePerMonth,
          statPercentColor: "text-emerald-500",
          statDescripiron: "Max per month",
          statIconName: "fas fa-chart-pie",
          statIconColor: "bg-orange-500"
        },
        {
          statSubtitle: "Actions",
          statTitle: (this.thisMonthStats?.actions || 0),
          statArrow: null,
          statPercent: maxActionPerMonth,
          statPercentColor: "text-emerald-500",
          statDescripiron: "Max Per Month",
          statIconName: "fas fa-percent",
          statIconColor: "bg-pink-500"
        },
        {
          statSubtitle: "Submissions",
          statTitle: this.thisMonthStats?.webforms || 0,
          statArrow: null,
          statPercent: maxWebform,
          statPercentColor: "text-emerald-500",
          statDescripiron: "Max per months",
          statIconName: "fas fa-percent",
          statIconColor: "bg-emerald-500"
        }
      ]

    }
  },
  methods: {
    goToDemoTemplate() {
      window.open('https://wishcompta.notion.site/Demo-Webleast-Template-afc82a354d5a47a38730cebbcb41abf8')
    }
  }
};
</script>
