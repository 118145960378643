const standardPriceId = process.env.VUE_APP_standardPriceId
const prodPriceId = process.env.VUE_APP_prodPriceId
const entreprisePriceId = process.env.VUE_APP_entreprisePriceId

const plans = [
  {
    price: '€16.7/Month',
    name: 'Standard Plan',
    description: 'For individuals looking to automate daily tasks of their work',
    priceId: standardPriceId,
    maxValues:{
      buildTime: 0,
      webforms:3,
      webformSubmissions: 200,
      automations: 3, 
      actions: 1000,
      actionPerAutomation: 2
    }
  },
  {
    price: '€49.5/Month',
    name: 'Pro Plan',
    description: 'Build your website and connect with your customers',
    priceId: prodPriceId,
    maxValues:{
      buildTime: 1200,
      webforms:10,
      webformSubmissions: 1000,
      automations: 10, 
      actions: 5000,
      actionPerAutomation: 5
    }
  },
  {
    price: '€139/Month',
    name: 'Entreprise Plan',
    description: 'For organizations seeking entreprise-grade features',
    priceId: entreprisePriceId,
    maxValues:{
      buildTime: 5000,
      webforms:20,
      webformSubmissions: 10000,
      automations: 20, 
      actions: 15000,
      actionPerAutomation: 8
    }
  },
]

      const getCurrentPlan = ({trialEndDate, customSubscription, currentSubscription}) => {
        var currentPlan = null
       
        
        if(customSubscription){
          if(!isSubscriptionExpired(customSubscription)){
            currentPlan = customSubscription.plan
          }
        }
        if(!currentPlan && currentSubscription){
          if(!isSubscriptionExpired(currentSubscription)){
            currentPlan =  plans.find(p => p.priceId === currentSubscription.priceID)
          } 
        }
      
        if(currentPlan){
          return currentPlan
        }
        if(trialEndDate) {
          if(trialEndDate > Date.now()){
            return plans[0] // Standard
          }
        }
        return null

      }

      

      const isSubscriptionExpired = (subscription) => {
        if(!subscription){
          return true
        }
        
        const now = Date.now()
        if(!subscription.current_period_end || subscription.current_period_end * 1000 < now){
          return true
        }
        return false
      }

      
      const canAddAutomation = ({currentPlan, automationCount}) => {
        if(!currentPlan){
          throw new Error(`You don't have a subscription plan`)
        }
        const maxValues = currentPlan.maxValues
        return automationCount < maxValues.automations
      }

      const canAddWebform = ({currentPlan, webformCount}) => {
        if(!currentPlan){
          throw new Error(`You don't have a subscription plan`)
        }
        const maxValues = currentPlan.maxValues
        return webformCount < maxValues.webforms
      }

      const restRunBuildTime = ({currentPlan, buildTimeCount}) => {
        if(!currentPlan){
          throw new Error(`You don't have a subscription plan`)
        }
        const maxValues = currentPlan.maxValues
        return (1 - buildTimeCount / (maxValues.buildTime * 1000))

      }

      const restRunActions = ({currentPlan, actionsCount}) => {
        
        if(!currentPlan){
          throw new Error(`You don't have a subscription plan`)
        }
        const maxValues = currentPlan.maxValues
        return (1 - actionsCount / maxValues.actions)

      }


      exports.plans = plans
      exports.canAddAutomation = canAddAutomation
      exports.canAddWebform = canAddWebform
      exports.getCurrentPlan = getCurrentPlan
      exports.restRunBuildTime = restRunBuildTime
      exports.restRunActions = restRunActions
