<template>
  <div>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="
            absolute
            top-0
            w-full
            h-full
            bg-blueGray-800 bg-no-repeat bg-full
          "
        ></div>
        <router-view />
        <footer-small absolute />
      </section>
    </main>
  </div>
</template>
<script>
import FooterSmall from "@/components/Footers/FooterSmall.vue";


export default {
  data() {
    return {
    };
  },
  components: {
    FooterSmall,
  },
  mounted() {
    let iframScript = document.createElement('script')
    iframScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/iframe-resizer/4.3.2/iframeResizer.contentWindow.js')
    document.head.appendChild(iframScript)
  },
};
</script>
