import { createWebHistory, createRouter } from "vue-router";
import {sleep} from '@/utils/index.js'
// import { ElLoading } from 'element-plus'
// mouting point for the whole app
// import { auth } from './firebase'
// layouts
// Vuex Store
import store from './store'
import Admin from "@/layouts/Admin.vue";
import Edit from "@/layouts/Edit.vue";
import FullPage from "@/layouts/FullPage.vue";
import FullPageOrange from "@/layouts/FullPageOrange.vue";
import EmptyLayout from "@/layouts/EmptyLayout.vue";

const routes = [
  
  {
    path: "/admin",
    redirect: "/admin/dashboard",
    component: Admin,
    children: [
      {
        path: "/admin/dashboard",
        component: () => import('@/views/admin/Dashboard.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/pagesSettings",
        component: () => import('@/views/admin/PagesSetting.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/customers",
        component: () => import('@/views/admin/Customers.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/webform",
        component: () => import('@/views/admin/WebForm.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/automations",
        component: () => import('@/views/admin/Automations.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/automation/:id",
        component: () => import('@/views/admin/EditAutomation.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/buildsSettings",
        component: () => import('@/views/admin/BuildSettings.vue'),
         meta: {
          requiresAuth: true
        }
      },
      
      {
        path: "/admin/siteMapSettings",
        component: () => import('@/views/admin/SitemapSettings.vue'),
         meta: {
          requiresAuth: true
        }
      },
       {
        path: "/admin/redirectsSettings",
        component: () => import('@/views/admin/redirectsSettings.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/integrations",
        component: () => import('@/views/admin/Integrations.vue'),
         meta: {
          requiresAuth: true
        }
      },
      {
        path: "/admin/billing",
        component: () => import('@/views/admin/Billing.vue'),
         meta: {
          requiresAuth: true
        }
      },
      
      {
        path: "/admin/tables",
        component: () => import('@/views/admin/Tables.vue'),
      },

    ],
  },
  {
    path: "/edit",
    redirect: "/edit/webformTemplate",
    component: Edit,
    children: [
      {
        path: "/edit/webformTemplate/:id",
        component: () => import('@/views/edit/EditWebform.vue'),
         meta: {
          requiresAuth: true
        }
      }
    ],
  },

  {
    path: "/webformPages",
    redirect: "/webform",
    component: FullPageOrange,
    children: [
      {
        path: "/webform",
        component: () => import('@/views/Webform/WebformPage.vue')
      },
    ],
  },
  {
    path: "/embedForm",
    redirect: "/embedForm",
    component: EmptyLayout,
    children: [
      {
        path: "/embedForm",
        component: () => import('@/views/Webform/WebformPage.vue')
      },
    ],
  },

  {
    path: "/",
    redirect: "/login",
    component: FullPage,
    children: [
      {
        path: "/login",
        component: () => import('@/views/auth/Login.vue'),
      },
       {
        path: "/register",
        component: () => import('@/views/auth/Register.vue'),
      },
        {
        path: "/forgot",
        component: () => import('@/views/auth/ForgotPassword.vue'),
      },
        {
        path: "/notionSuccess",
        component: () => import('@/views/auth/NotionSuccess.vue')
      },
        {
        path: "/stripe/mandate/:mode/:projectId/:customerId",
         component: () => import('@/views/stripePages/mandate.vue')
      },
      {
        path: "/stripe/success",
        component: () => import('@/views/stripePages/MandateOK.vue')
      },
      {
        path: "/stripe/cancel",
        component: () => import('@/views/stripePages/MandateFail.vue')
      },
      
    ],
  },
  
  { path: "/:pathMatch(.*)*", redirect: "/" },
  { path: "/404", redirect: "/admin/dashboard" },
  { path: '/*', redirect: '/admin/dashboard' }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  document.title = 'Webleast'
  var needToLogin = true
  for(var i=0;i<10;i++){
    if (requiresAuth && !store.state.projectId) {
      await sleep(1000)
    } else {
      needToLogin = false
      break
    }
  }
  // when use sign out, vue already mounted, then router called without waiting auth change call back, give 5s to wait
  if(needToLogin === false){
    next()
  } else {
    next('/login')
  }
})

export default router


