
import firebase from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

// firebase init - add your own config here
// const firebaseConfig = {
//   apiKey: "AIzaSyAx0Y8AbMBWtYchtvP9R7cQR86u6HgBacM",
//   authDomain: "app-notion2web.firebaseapp.com",
//   projectId: "app-notion2web",
//   storageBucket: "app-notion2web.appspot.com",
//   messagingSenderId: "180242186233",
//   appId: "1:180242186233:web:90119b86bda6bbc9ef4728",
//   measurementId: "G-79R9TW4Z6X"
// }

const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
}



const firebaseApp = firebase.initializeApp(firebaseConfig)


// utils
const db = firebaseApp.firestore()
const auth = firebaseApp.auth()
const functions = firebaseApp.functions()
const storage = firebaseApp.storage()

// export utils/refs
export {
  db,
  auth,
  functions,
  storage
}
