<template>
  <div class="text-center">
    <el-button
      class="
        text-white text-sm
        font-bold
        uppercase
        px-6
        py-3
        rounded
        shadow
        hover:shadow-lg
        outline-none
        focus:outline-none
        mr-1
        mb-1
        w-full
        h-full
        ease-linear
        transition-all
        duration-150
      "
      :type="$slots['default'] ? 'success' : 'primary'"
      @click="integrateNotion"
      :loading="loading"
    >
      <slot></slot>
      <p v-if="!$slots['default']">Connect</p>
    </el-button>
  </div>
</template>
<script>
// import { auth, functions } from '@/firebase'
import { mapGetters } from 'vuex'


export default {
  name: 'NotionIntegreation',
  props: {

  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {

  },
  computed: {
    ...mapGetters(['uid', 'projectId', 'previewLink', 'domain', 'siteGlobal']),
  },

  methods: {
    integrateNotion() {
      // example ok callback : https://app.webleast.com/notionSuccess?code=27da75e8-07e3-468e-8ed5-297ad2b53d2c&state=
      // example cancel callback : https://app.webleast.com/notionSuccess?error=access_denied&state=
      const client_id = process.env.VUE_APP_notion_client_id
      const redirect_uri = process.env.VUE_APP_notion_redirect_uri
      window.open(`https://api.notion.com/v1/oauth/authorize?owner=user&client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code`, '_self')
    }
  }
};
</script>
