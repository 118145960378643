<template>
  <div v-if="projectId">
    <sidebar />
    <div class="relative md:ml-64 bg-blueGray-100">
      <admin-navbar />
      <header-stats />
      <div class="px-4 md:px-10 mx-auto w-full -m-24">
        <div
          v-if="!workspace_name"
          class="
            relative
            flex flex-col
            min-w-0
            break-words
            mb-6
            shadow-lg
            rounded
            bg-white
            px-12
            text-center
          "
        >
          <h2 class="text-2xl">
            Almost Ready 🎉 ! Connect Webleast to Notion.
          </h2>
          <p>
            Most Webleast functionalities require access to your Notion
            Workspace.
          </p>
          <p>
            If you have some difficulties, contact us at
            <a href="mailto:hello@webleast.com">hello@webleast.com</a>
          </p>
        </div>
        <el-row
          v-if="!workspace_name"
          class="
            min-w-0
            break-words
            mb-6
            shadow-lg
            rounded
            bg-white
            text-center
          "
        >
          <el-col
            class="text-left px-12"
            :xs="24"
            :sm="24"
            :md="16"
            :lg="16"
            :xl="16"
          >
            <h2 class="text-2xl">Connect to Notion</h2>
            <p>#1 Give access to your notion workspace</p>
          </el-col>
          <el-col :xs="24" :sm="24" :md="8" :lg="8" :xl="8">
            <div class="h-full">
              <NotionIntegration class="w-full h-full"></NotionIntegration>
            </div>
          </el-col>
        </el-row>
        <router-view v-if="workspace_name" />

        <footer-admin />
      </div>
    </div>
  </div>
  <div v-else>
    <main>
      <section class="relative w-full h-full py-40 min-h-screen">
        <div
          class="
            absolute
            top-0
            w-full
            h-full
            bg-blueGray-800 bg-no-repeat bg-full
          "
          :style="`background-image: url('${registerBg2}');`"
        ></div>
        <div class="container mx-auto px-4 h-full">
          <div class="flex content-center items-center justify-center h-full">
            <div class="w-full lg:w-4/12 px-4">
              <div
                ref="signInContainer"
                class="
                  relative
                  flex flex-col
                  min-w-0
                  break-words
                  w-full
                  mb-6
                  shadow-lg
                  rounded-lg
                  bg-blueGray-200
                  border-0
                "
              >
                <div class="my-auto px-4 lg:px-10 py-10 pt-0">
                  Project is setting up...
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>
<script>
import AdminNavbar from "@/components/Navbars/AdminNavbar.vue";
import Sidebar from "@/components/Sidebar/Sidebar.vue";
import HeaderStats from "@/components/Headers/HeaderStats.vue";
import FooterAdmin from "@/components/Footers/FooterAdmin.vue";
import { db, auth } from '@/firebase'
import { mapGetters } from 'vuex'
import registerBg2 from "@/assets/img/register_bg_2.png";
import NotionIntegration from '@/components/Notion/NotionIntegration'


export default {
  name: "admin-layout",
  components: {
    AdminNavbar,
    Sidebar,
    HeaderStats,
    FooterAdmin,
    NotionIntegration
  },
  data() {
    return {
      registerBg2: registerBg2,
      authListner: null
    }
  },
  created() {
    this.emitter.on("signOut", this.unlinkListner)
  },
  beforeUnmount() {
    this.emitter.off("signOut", this.unlinkListner)
  },
  computed: {
    ...mapGetters(['projectId', 'projectActive', 'notionCode', 'workspace_name']),
  },

  async mounted() {
    if (!this.projectId) {
      this.authListner = auth.onAuthStateChanged(async (user) => {
        if (user) {
          var uid = user.uid
          await this.checkProject(uid)
        } else {
          this.$message.warning('Please Login')
          this.$router.push('/login')
          return
        }
      })
    } else {
      if (this.notionCode) {
        this.$router.push({ path: '/notionSuccess', query: { code: this.notionCode } })
      }
    }
  },
  methods: {
    unlinkListner() {
      this.authListner && this.authListner()
    },
    async checkProject(uid) {
      const doc = await db.collection('projects').doc(uid).get()
      if (doc.exists) {
        const project = doc.data()
        project.id = doc.id
        this.$store.commit('SET_PROJECT', project)
        if (this.notionCode) {
          this.$router.push({ path: '/notionSuccess', query: { code: this.notionCode } })
        }
        return project
      } else {
        let that = this
        setTimeout(function () { that.checkProject(uid) }, 5 * 1000)
        return null
        // this.$router.push('/auth/login')
        // return
      }
    }
  }
}
</script>
