import { createApp } from "vue";
// styles

import mitt from 'mitt';
const emitter = mitt()

import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/styles/tailwind.css";
// import "@/assets/styles/index.css";
// element ui
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from "@/App.vue";

import { auth, db } from './firebase'


import { VueClipboard } from '@soerenmartius/vue3-clipboard'

// Vuex Store
import store from './store'

// Vue router
import router from './router'

import {sleep } from '../src/utils/index'

//
import { VueReCaptcha } from 'vue-recaptcha-v3'



// views for Admin layout












// views for Auth layout











// views without layouts


//
import VueLogger from 'vuejs3-logger'
const isProduction = process.env.NODE_ENV === 'production'
const logOptions = {
    isEnabled: true,
    logLevel : isProduction ? 'error' : 'debug',
    stringifyArguments : false,
    showLogLevel : true,
    showMethodName : true,
    separator: '|',
    showConsoleColors: true
};

// routes



import { ElLoading } from 'element-plus'

 const loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.7)',
      })





var vm 

auth.onAuthStateChanged(async (firebaseUser) => {
  if (firebaseUser) {
    for(let i = 0; i< 10; i++){
      var uid = firebaseUser.uid
      const doc = await db.collection('projects').doc(uid).get()
      if(doc.exists){
        const project = doc.data()
        project.id = doc.id
        store.commit('SET_PROJECT', project)
        break
      } else {
        await sleep(1000)
      }
    }
    
    
  } else  {

    store.commit('SET_PROJECT', null)
    
  }    

  if(!vm) {
      const app = createApp(App)

        app.use(VueLogger, logOptions)
        app.use(VueReCaptcha, { siteKey: process.env.VUE_APP_recapcha_siteKey, loaderOptions:{useEnterprise:true, autoHideBadge:true} })
        app.use(store)
        app.use(router)
        app.use(ElementPlus)
        app.use(VueClipboard)
        app.config.globalProperties.emitter = emitter
      vm = app.mount("#app")
  }
  loading.close()
  

  
})


